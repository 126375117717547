/* eslint-disable */
import React from 'react';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { connect } from 'react-redux';

import { setSidebarToggleMobile } from '../../../reducers/ThemeOptions';

import MenuCabecalho from './MenuCabecalho.js';
import MenuConteudo from './MenuConteudo.js';
import CabecalhoUsuarioBox from './CabecalhoUsuarioBox';

import './Menu.css'

const Menu = props => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const {
    sidebarStyle,
    sidebarShadow,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    cssClass,
    collapsed,
    verifyClose
  } = props;

  return (
    <>
      <div id="menu-sidebar"
        className={clsx('app-sidebar', sidebarStyle, {
          'app-sidebar--shadow': sidebarShadow
        })}
        >
        <MenuCabecalho collapsed={collapsed}/>
         <div className="app-header--pane">          
          <CabecalhoUsuarioBox />
        </div>                  
        <div className="app-sidebar--content">          
          <MenuConteudo setSidebarToggleMobile={setSidebarToggleMobile} verifyClose={verifyClose}/>
        </div>
      </div>
      <div
        onClick={toggleSidebarMobile}
        // className={clsx('app-sidebar-overlay', {
        //   'is-active': sidebarToggleMobile
        // })}
      />
    </>
  );
};

const mapStateToProps = state => ({
  sidebarShadow: state.ThemeOptions.sidebarShadow,
  sidebarFooter: state.ThemeOptions.sidebarFooter,
  sidebarStyle: state.ThemeOptions.sidebarStyle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
