/* eslint-disable */
import React from 'react';
import api from "./api";
import jwt from 'jsonwebtoken';

const { createContext , useContext } =  React ;

const  DashContext  =  createContext ( null );

export class DashboardService {  

  async getAll() {      
      return await api
            .get(`painel`)
            .then(res => res.data);  
  }

  async getPaineisUsuario() {      
    return await api
          .post(`painel/usuario`)
          .then(res => res.data);  
  }

  async createPanel(data) {
    // return await api.post('painel', data)
  }

  async getPainelEmpresa() {
    const pbiToken = sessionStorage.getItem('@ProsperMonitor:pbiToken');

    const result = await api
          .post(`painel/empresa`, {
            'pbiToken': pbiToken
          })
    
    return result.data;
  }

  async getDashboardEmpresa() {
    const pbiToken = sessionStorage.getItem('@ProsperMonitor:pbiToken');

    const result = await api
          .post(`painel/dashboard`, {
            'pbiToken': pbiToken
          })
    
    return result.data;
  }

  async getReportEmpresa(id) {
    const pbiToken = sessionStorage.getItem('@ProsperMonitor:pbiToken');

    const result = await api
          .post(`painel/dashboard/${id}`, {
            'pbiToken': pbiToken
          })
    
    return result.data;
  }

  async verifyBpoToken() {
    const bpoToken = sessionStorage.getItem('@ProsperMonitor:bpoToken');
    const decoded = jwt.decode(bpoToken);
    let isLogout = false;
    if ( (decoded) && (decoded.exp) ) {
      const expires = new Date(decoded.exp * 1000);  
      const currentDate = new Date();
      currentDate.setMinutes( currentDate.getMinutes() - 2);
      if (expires < currentDate) {
        isLogout = true;
      }
    }
    return isLogout;  
  }
} 