/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import './Login.css'
import { useAuth } from './Autenticacao';
import { AzureService } from '../../../services/azure.service';

import {
  FormGroup,
  Input,
  Button
} from 'reactstrap';

import logoProsper from '../../../assets/images/logo-prosper.png';

import { invalidMessage } from '../../comum/FormMessage';


export default function PaginaLogin() {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, formState: { errors } } = useForm();

  const { signIn } = useAuth();

  const history = useHistory();

  const onSubmit = useCallback(
    async data => {
      try {
        setIsLoading(true);
        const result = await signIn({
          email: data.email,
          senha: data.senha
        });

        if (result.status === 200) {
          history.push('/dashboard');
          history.go();
        }
      } catch (err) {
        console.log(err);
        setIsError(true);
      }
      setIsLoading(false);
    },
    [history, signIn]
  );

  const goToReset = () => {
    history.push('/reset-password');
  }

  useEffect(() => {
    if (isError) {
      toast.warn('Erro de Autenticação! Verifique o nome de usuário e senha.', {
        containerId: 'B'
      });
      setIsError(false);
    }
  }, [isError]);

  useEffect( () => {
    const azureService = new AzureService();
    azureService.updatePowerBICapacity();
  }, [])

  return (
    <div className="telaprincipal">
      <div className="telalogin">
        <div className="logopequeno">
        <img
              className="mt-4 mb-4 imgCustom"                            
              src={logoProsper}
              alt="Prosper"
            />
        </div>
        <div className="caixalogin">
        <form
                onSubmit={handleSubmit(onSubmit)}
                className="">
                  <p className="label-login">Usuário</p>
                <FormGroup className="mb-3 input-custom">
                  <Controller
                    control={control}
                    placeholder="E-mail"
                    type="email"
                    name="email"
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => <Input {...field} />}
                    autoFocus={true}
                  />
                  {(errors.email?.type=== "required" && (
                    invalidMessage('Este campo é obrigatório')
                  ))}                  
                </FormGroup>
                <div className="forgot-div">
                <p className="label-login less-margin">Senha</p>
                  <a
                    className="text-senha"
                    href="javascript:void(0);"
                    onClick={goToReset}>
                  Esqueci minha senha
                              </a>

                </div> 
                
                <FormGroup className="input-custom">
                  <Controller
                    control={control}
                    placeholder="Senha"
                    type="password"
                    name="senha"
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => <Input {...field} type="password" />}
                  />
                  {(errors.senha?.type === "required" && (
                    invalidMessage('Este campo é obrigatório')
                  ))}                  
                </FormGroup> 
               
                <div className="text-center py-2">
                  <Button className="buttonentrar"
                    color="primary"
                    type="submit"
                    disabled={isLoading ? true : false}
                    >
                    Entrar
                   </Button>
                    
                </div>
                <div>
               
                </div>
              </form>
        </div>
        <p className="text-rodape"> Prosper - Gestão Inteligente 2021</p> 
      </div>

      <div className="mainscreen"></div>
    </div>
  );
}
