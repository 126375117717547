import api from "./api";

export class CompaniesService {  

  async getAll() {
      return await api
            .get('empresa')
            .then(res => res.data);  
  }

  async getById(id) {
      return await api
            .get('empresa/'+id)
            .then(res => res.data[0]);  
  }

} 