import React from 'react';
import { Button } from 'reactstrap';

const CabecalhoMenu = () => {
  return (
    <>
      <div className="app-header-menu">
        <span className="position-relative">
          <Button
            size="sm"
            hidden={true}
            color="neutral-dark"
            id="megaMenuPopover"
            className="mr-3 btn-transition-none">
            Opção 01
          </Button>
        </span>
        <span className="position-relative">
          <Button
            size="sm"
            hidden={true}
            color="neutral-dark"
            id="megaMenuPopover"
            className="mr-3 btn-transition-none">
            Opção 02
          </Button>
        </span>
      </div>
    </>
  );
};

export default CabecalhoMenu;
