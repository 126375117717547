/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import './Login.css'
import { useAuth } from './Autenticacao';


import {
  FormGroup,
  Input,
  Button
} from 'reactstrap';

import logoProsper from '../../../assets/images/logo-prosper.png';

import { invalidMessage } from '../../comum/FormMessage';


export default function PaginaPassRecovery() {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMsg, setIsMsg] = useState(false);

  const { control, handleSubmit, formState: {errors} } = useForm();

  const { recovery } = useAuth();

  const history = useHistory();

  const onSubmit = useCallback(
    async data => {      
      try {
        setIsLoading(true);
        const result = await recovery({
          email: data.email          
        });
        if (result.status === 200) {
          setIsMsg(true);
          history.push('/login');
        }
        else {
          setIsError(true);  
        }
      } catch (err) {
        console.log(err);
        setIsError(true);
      }
      setIsLoading(false);
    },
    [history, recovery]
  );

  const goToLogin = () => {
    history.push('/login');
  }

  useEffect(() => {
     if (isError) {
       toast.warn('Erro ao recuperar a senha! Verifique se esse nome de usuário está cadastrado.', {
         containerId: 'B'
       });
     }
  }, [isError]);

  useEffect(() => {
    if (isMsg) {
      toast.info('E-mail para recupuração de senha encaminhado.', {
        containerId: 'B'
      });
    }
 }, [isMsg]);

  return (
    <div className="telaprincipal">
      <div className="telalogin">
        <div className="logopequeno">
        <img
              className="mt-4 mb-4 imgCustom"                            
              src={logoProsper}
              alt="Prosper"
            />
        </div>
        <div className="caixalogin">
        <form
                onSubmit={handleSubmit(onSubmit)}
                className="">
                  <p className="label-login">E-mail:</p>
                <FormGroup className="mb-3 input-custom">
                  <Controller
                    control={control}
                    placeholder="E-mail"
                    type="email"
                    name="email"
                    defaultValue=""
                    rules={{ required: true }}
                    autoFocus={true}
                    render={({ field }) => <Input {...field} />}
                  />
                  {errors.email?.type === 'required' &&
                    invalidMessage('Este campo é obrigatório')}
                </FormGroup>
               
                <div className="text-center py-2">
                  <Button className="buttonentrar"
                    color="primary"
                    type="submit"
                    disabled={isLoading ? true : false}
                    >
                    Recuperar
                   </Button>
                   <a
                    className="text-cancel"
                    href="javascript:void(0);"
                    onClick={goToLogin}>
                  Voltar para o login
                              </a>
                    
                </div>
                <div>
               
                </div>
              </form>
        </div>
        <p className="text-rodape"> Prosper - Gestão Inteligente 2021</p> 
      </div>

      <div className="mainscreen"></div>
    </div>
  );
}
