
import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../../../services/api';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const bpoToken = sessionStorage.getItem('@ProsperMonitor:bpoToken');
    const usuarioAutenticado = sessionStorage.getItem('@ProsperMonitor:usuario');
    const empresa = sessionStorage.getItem('@ProsperMonitor:empresa');
    const perfil = sessionStorage.getItem('@ProsperMonitor:perfil');
    const pbiToken = sessionStorage.getItem('@ProsperMonitor:pbiToken');

    if (bpoToken && usuarioAutenticado) {
      return { bpoToken, usuarioAutenticado, empresa, perfil, pbiToken };
    }
    return {};
  });

  const signIn = useCallback(async ({ email, senha }) => {
    const response = await api.post('autenticacao', {
      email,
      senha
    });

    const { bpoToken, pbiToken } = response.data;
    const usuario = response.data.dadosUsuario[0].nome;
    const idUsuario = response.data.dadosUsuario[0].id;
    const idEmpresa = response.data.dadosUsuario[0].idEmpresa;
    const perfil = response.data.dadosUsuario[0].tipoPerfil;    

    const AuthStr = 'Bearer '.concat(bpoToken);

    const dadosEmpresa = await api.get(`empresa/${idEmpresa}`, {
      headers: { Authorization: AuthStr }
    });

   const nomeEmpresa = dadosEmpresa.data[0].nome;

    sessionStorage.setItem('@ProsperMonitor:email', email);
    sessionStorage.setItem('@ProsperMonitor:bpoToken', bpoToken);
    sessionStorage.setItem('@ProsperMonitor:pbiToken', pbiToken);
    sessionStorage.setItem('@ProsperMonitor:usuario', usuario);
    sessionStorage.setItem('@ProsperMonitor:perfil', perfil);
    sessionStorage.setItem('@ProsperMonitor:idUsuario', idUsuario);
    sessionStorage.setItem('@ProsperMonitor:empresa', nomeEmpresa);
    sessionStorage.setItem('@ProsperMonitor:idEmpresa', idEmpresa);

    await setData({ bpoToken, usuario, email, nomeEmpresa, idEmpresa, perfil, pbiToken });

    return response;
  }, []);
  
  const recovery = useCallback(async ({ email }) => {    
    const response = await api.post(`autenticacao/reset-password/${email}`);

    return response;
  }, []);

  const definePassword = useCallback(async ({ token, senha }) => {        
    const response = await api.post('autenticacao/define-password', {
      token,
      senha
    });

    return response;
  }, []);

  const SignOut = useCallback(async () => {    
    const idUsuario = sessionStorage.getItem('@ProsperMonitor:idUsuario');
    await api.post(`/autenticacao/logout/${idUsuario}`);

    sessionStorage.removeItem('@ProsperMonitor:email');
    sessionStorage.removeItem('@ProsperMonitor:bpoToken');
    sessionStorage.removeItem('@ProsperMonitor:usuario');
    sessionStorage.removeItem('@ProsperMonitor:perfil');
    sessionStorage.removeItem('@ProsperMonitor:empresa');
    sessionStorage.removeItem('@ProsperMonitor:idEmpresa');
    sessionStorage.removeItem('@ProsperMonitor:idUsuario');
    sessionStorage.removeItem('@ProsperMonitor:pbiToken');    
  
    setData({});    
  }, []);

  return (
    <AuthContext.Provider value={{ usuario: data, signIn, SignOut, recovery, definePassword }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('a função useAuth deve ser usado com o AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
