import api from "./api";

export class AzureService {  

  async updatePowerBICapacity() {
    console.log('updatePowerBICapacity');
    return await api.post('autenticacao/update-azure-capacity')
      .then((res) => res.data );
  }

  async getPowerBICapacityStatus() {
    return await api.post('autenticacao/get-azure-status')
      .then((res) => {
        if (res.data) {
          return res.data.capacity;
        }
        else {
          return null;
        }
      });
  }

} 