/* eslint-disable */
import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import { Row, Col, Card, Container, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DashForm from './dashboardForm';
import DataTable from 'react-data-table-component';
import { AiFillDelete } from "react-icons/ai";
import './dashboard.css';
import { DashboardService } from "../../../services/dashboard.service"
import { Alert } from 'reactstrap';

const token = sessionStorage.getItem('@ProsperMonitor:bpoToken');
const idEmpresa = sessionStorage.getItem('@ProsperMonitor:idEmpresa');
const AuthStr = 'Bearer '.concat(token);
const dashboardService = new DashboardService();

export default function AdmDashboard(props) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companie, setCompanie] = useState(undefined);
  const [panels, setPanels] = useState([]);
  const [panel, setPanel] = useState({
      id: '',
      nome: '',                
      tipo: '',                
      idEmpresa: '',                
      ordem: '',
      workspaceID: '',
      reportID: '',
      datasetID: ''
  });
  
  const [noDataString, setNoDataString] = useState("Buscando dados...");

  const [modal, setModal] = useState(false);

  const perfil = sessionStorage.getItem('@ProsperMonitor:perfil');

  const toggle = () => setModal(!modal);
  
  const actions = perfil == 'administrador' ? <Button key="add" onClick={toggle}>Novo Painel</Button> : '';

  const columns = [
    {
      name: 'Nome',
      selector: 'nome',
      sortable: true,
    },
    {
      name: 'Ordem',
      selector: 'ordem',
      sortable: true,
    },{
      name: 'Ações',
      cell: (row) => <AiFillDelete className="icon-list-main" onClick={() => handleDelete(row)}/>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [visible, setVisible] = useState(false);
  const [bodyVisible, setBodyVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('success');

  const onShowAlert = (message, type)=>{    
    setMessage(message);
    setType(type);
    setVisible(true);    
    setTimeout(()=>{
      setVisible(false)
    },3000);    
  }

  const onShowAlertBody = (message, type)=>{    
    setMessage(message);
    setType(type);
    setBodyVisible(true);    
    setTimeout(()=>{
      setBodyVisible(false)
    },3000);    
  }

  function handleDelete(row) {
        
    if (window.confirm(`Tem certeza que deseja excluir:\r ${row.nome}?`)) {      
      removePainel(row.id);                
    }
  };

  const goToDetail = row => {
    setPanel(row);
    toggle();
  }

  const BasicTable = () => (
    <DataTable
      columns={columns}
      data={panels}
      defaultSortField="ordem"      
      actions={actions}      
      onRowClicked={(row)=>{goToDetail(row)}} 
      noDataComponent={noDataString}
      highlightOnHover={true}
      pointerOnHover={true}
    />
  );

  useEffect(() => {
    const id = props.company.id;
    dashboardService.getAll().then(
        (res) => {        
          if(perfil == 'administrador') 
            // setPanels(res);
            setPanels(res.filter(u => u.idEmpresa == id));
          
          if(res.length == 0)            
            setNoDataString("Sem dados para mostrar.");  
        },
        ()=>{
            setIsError(true)
        }
    );    
  }, [props]);

  const onSubmit = async data => { 
    setIsLoading(true);
    const postData = Object.assign({ idEmpresa: props.company.id }, data);
    delete postData.id; 
      if (!data.id) {
        await api
          .post(`painel`, postData, {
            headers: { Authorization: AuthStr }
          })
          .then(res => {            
            let newPanel = Object.assign({ id: "" }, postData);
            const updatedPanels = [...panels, newPanel];
            setPanels(updatedPanels);
            toggle();
            setIsLoading(false);
            onShowAlertBody('Painel cadastrado com sucesso.', 'success'); 
          })
          .catch(err => {
            setIsError(true);
            setIsLoading(false);
            onShowAlert(err.response.data.mensagem, 'danger'); 
          });
      }else {
        await api
          .put(`painel/${data.id}`, postData, {
            headers: { Authorization: AuthStr }
          })
          .then(res => {
            const updatedPanels = panels.map(item => {
              if (item.id === data.id) {
                postData.id = item.id;
                item = postData;
              }
              return item;
            });                        
            setPanels(updatedPanels);
            toggle();
            setIsLoading(false);
            onShowAlertBody('Painel editado com sucesso.', 'success'); 
          })
          .catch(err => {
            setIsError(true);
            setIsLoading(false);
            onShowAlert(err.response.data.mensagem, 'danger'); 
          });
      } 
  };

  async function removePainel(id) {    
    setIsLoading(true);
    await api
      .delete(`painel/${id}`, {
        headers: { Authorization: AuthStr }
      })
      .then(_ => {        
        const updatedPanels = panels.filter(item => item.id !== id);;
        setPanels(updatedPanels);
        setIsLoading(false);
      })
      .catch(err => {
        setIsError(true);
        setIsLoading(false);
      });
  }

  return (
    <Container>      
      <Card className="p-2 my-2">
        <Row>
          <Col md="12">            
            <BasicTable/>            
          </Col>
        </Row>
      </Card>      
      <Modal isOpen={modal} toggle={toggle} className="dash-modal">
        <Alert color={type} isOpen={visible} >
          {message}
        </Alert>   
        <ModalHeader toggle={toggle}>Dados do Painel</ModalHeader>
        <ModalBody>        
          <DashForm
            defaultValues={panel}
            setDefaultValues={setPanel}
            {...{ setCompanie, onSubmit, isLoading }}
          />
        </ModalBody>
        <ModalFooter>                              
          <Button color="secondary" onClick={toggle}>Cancelar</Button>
        </ModalFooter>
      </Modal>
      <Alert color={type} isOpen={bodyVisible} >
        {message}
      </Alert>  
    </Container>
  );
}
