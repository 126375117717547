/* eslint-disable */
import React from 'react';

import { useEffect, useState } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';

import { useHistory } from "react-router-dom";

import { FaUsers, FaSignOutAlt } from "react-icons/fa";
import { AiFillDashboard, AiFillBank } from "react-icons/ai";

import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../../reducers/ThemeOptions';

import { Airplay, Users } from 'react-feather';

import { useAuth } from 'modulos/monitor/login/Autenticacao';

const MenuConteudo = props => {
  const { setSidebarToggleMobile } = props;
  
  const { SignOut } = useAuth();

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const history = useHistory();

  const perfil = sessionStorage.getItem('@ProsperMonitor:perfil');

  const [isMobile, setIsMobile] = useState(false);

  const customSignOut = async () => {
    await SignOut();
    handleClick('/login');
  }

  function handleClick(route) {
    toggleSidebarMobile();
    history.push(route);
    props.verifyClose();
  }

  useEffect(() => {
    if(window.outerWidth < 900)      
      setIsMobile(true);  
  }, []);

  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>Geral</span>
          </div>
          <ul>
            <li>
            <a                
                activeclassname="active"                
                onClick={() => handleClick('/dashboard')}
                className="nav-link-simple"
                to="">
                <span className="sidebar-icon">
                  <Airplay />
                </span>                
                <span className="sidebar-item-label">Painéis {isMobile}</span>
              </a>
            </li>              
          </ul>
          {perfil == 'administrador' || perfil == 'gestor' ? 
          <div className="sidebar-header">
            <span>Configurações</span>
          </div>          
          : ''}
          <ul>
          {perfil == 'administrador' || perfil == 'gestor' ? 
            <li>
              <a                
                activeclassname="active"
                onClick={() => handleClick('/usuarios')}
                className="nav-link-simple">
                <span className="sidebar-icon">
                  <FaUsers />
                </span>
                <span className="sidebar-item-label">Usuários</span>
              </a>
            </li>
            : ''
            }  
            {perfil == 'administrador' ? 
            <>
            {/* <li>
              <a                
                activeclassname="active"
                onClick={() => handleClick('/adm-dashboard')}
                className="nav-link-simple">
                <span className="sidebar-icon">
                  <AiFillDashboard />
                </span>
                <span className="sidebar-item-label">Painéis</span>
              </a>
            </li>                        */}
            <li>
              <a                
                activeclassname="active"
                onClick={() => handleClick('/empresas')}
                className="nav-link-simple">
                <span className="sidebar-icon">
                  <AiFillBank />
                </span>
                <span className="sidebar-item-label">Empresas</span>
              </a>
            </li>
            </>
            : ''
            }           
            <li>
              <a                
                activeclassname="active"
                onClick={customSignOut}
                className="nav-link-simple">
                <span className="sidebar-icon">
                  <FaSignOutAlt />
                </span>
                <span className="sidebar-item-label">Sair</span>
              </a>
            </li>
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = state => ({
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuConteudo);
