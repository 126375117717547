import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import Routes from './routes';
import ScrollToTop from './utils/ScrollToTop';
import { ToastContainer, toast } from 'react-toastify';
import "antd/dist/antd.css";
import 'react-toastify/dist/ReactToastify.css';
import "powerbi-client/dist/powerbi.js";
import "./App.css"

import { AuthProvider } from './modulos/monitor/login/Autenticacao';

const store = configureStore();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </ScrollToTop>
          <ToastContainer
            enableMultiContainer
            containerId={'A'}
            position={toast.POSITION.BOTTOM_LEFT}
            autoClose={5000}
            closeOnClick
          />
          <ToastContainer
            enableMultiContainer
            containerId={'B'}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={5000}
            closeOnClick
          />
          <ToastContainer
            enableMultiContainer
            containerId={'C'}
            position={toast.POSITION.BOTTOM_CENTER}
            autoClose={5000}
            closeOnClick
          />
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
