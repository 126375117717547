var host;
if(window.location.origin.indexOf('localhost') > -1){
    host = 'https://localhost';
}else {
    host = 'https://prosperbpo.com';
}
export const SERVER_HOST = host;
export const SERVER_PORT = 8000;
export const QLIK_SERVER_HOST = 'https://prosperbpo.com';
export const QLIK_SERVER_PORT = 8443;
