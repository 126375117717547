/* eslint-disable */
import React from 'react';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import {
  setSidebarToggle,
  setSidebarToggleMobile
} from '../../../reducers/ThemeOptions';

import prosperLogo from '../../../assets/images/logo-prosper-white.png';
import prosperIcon from '../../../assets/images/icon-prosper-white.png';

const MenuCabecalho = props => {
  const {
    collapsed
  } = props;
  return (
    <>
      <div className="app-sidebar--header">
        <div className="app-sidebar-logo">
          <Link
            to="/dashboard"
            title="Prosper Monitor"
            className="app-sidebar-logo">            
              <img alt="Prosper Monitor" src={props.collapsed == true ? prosperIcon : prosperLogo} />
          </Link>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuCabecalho);
