import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons'
import {
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown
} from 'reactstrap';

import { Link } from 'react-router-dom';

const CabecalhoUsuarioBox = () => {

  const usuario = sessionStorage.getItem('@ProsperMonitor:usuario');
  const empresa = sessionStorage.getItem('@ProsperMonitor:empresa');

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Dropdown className="position-relative m-12" isOpen={isOpen} toggle={toggle}>
        <DropdownToggle
          color="link"          
          className="p-0 text-left d-flex btn-transition-none align-items-center">
          <span className="pl-2">
            <FontAwesomeIcon
              icon={faUser}
              className="white userIcon"
            />
          </span>
          <div className="pl-8 pl-xl-3">            
            <div className="white font-weight-bold">{usuario}</div>
            <span className="white text-black-50">{empresa}</span>
          </div>
          <span className="pl-1 pl-xl-3">
            <FontAwesomeIcon
              icon={['fas', 'angle-down']}
              className="opacity-5 white"
            />
          </span>
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-lg overflow-hidden">
          <ListGroup flush className="text-left bg-transparent">
            <ListGroupItem className="rounded-top">
              <Nav pills className="nav-neutral-primary flex-column">
                <NavItem className="nav-header d-flex text-primary pt-1 pb-2 font-weight-bold align-items-center">
                  <div>Perfil do Usuário</div>
                </NavItem>
                <NavItem>
                  <Link to="/perfilDoUsuario" className='nav-link' onClick={toggle}>
                      Editar Perfil
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/alterarsenha" className='nav-link' onClick={toggle}>
                      Alterar Senha
                  </Link>
                </NavItem>
              </Nav>
            </ListGroupItem>          
          </ListGroup>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default CabecalhoUsuarioBox;
