/* eslint-disable */
import React from 'react';
import { useEffect, useState } from 'react';
import { CompaniesService } from '../../../../services/companies.service';
import { EtlsService } from '../../../../services/etls.service';
import { Row, Col, Card, Container, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useParams } from "react-router";
import DataTable from 'react-data-table-component';
import { AiFillDelete } from "react-icons/ai";
import EtlForm from './etlForm';
import { TabContent, TabPane, Nav, NavItem, NavLink, Alert } from 'reactstrap';
import { toast } from 'react-toastify';
import { RingLoader } from 'react-spinners';
import classnames from 'classnames';
import AdmDashboard from '../../admDashboard/index';

import '../company.css';

export default function DetalheEmpresa() {
  const companiesService = new CompaniesService();
  const etlsService = new EtlsService();
  const [company, setCompany] = useState([]);
  const [isError, setIsError] = useState(false);
  let { id } = useParams();
  const [modalNewETL, setModalNewETL] = useState(false);
  const [modalHistory, setModalHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('success');
  const [noDataString, setNoDataString] = useState("Buscando dados...");

  const [etls, setEtls] = useState([]);
  const [etl, setEtl] = useState({
    id: '',
    titulo: '',
    hora: '',
    idEmpresa: id
  });

  const toggleNewETL = () => setModalNewETL(!modalNewETL);

  const [activeTab, setActiveTab] = useState('1');

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const actions = <Button key="add" onClick={toggleNewETL}>Novo ETL</Button>;
  const columns = [
    {
      name: 'Título',
      selector: 'titulo',
      sortable: true,
    },
    {
      name: 'Hora',
      selector: 'hora',
      sortable: true,
    }, {
      name: 'Ações',
      cell: (row) => <AiFillDelete className="icon-list-main" onClick={() => handleDelete(row)} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    if (isError) {
      toast.warn('Erro no processo de ETL. Contacte o administrador do sistema.', {
        containerId: 'B'
      });
      setIsError(false);
    }
  }, [isError]);


  const onSubmit = async data => {
    const postData = Object.assign({ idEmpresa: id }, data);
    delete postData.id;
    setIsLoading(true);

    etlsService.create(postData).then(
      (res) => {
        setIsLoading(false);
        toggleNewETL();
        getEtls(id);
        toast.info('ETL criado com sucesso.', { containerId: 'B' });
      },
      (err) => {
        setIsLoading(false);
        toggleNewETL();
        toast.warn('Erro ao criar o ETL.', { containerId: 'B' });
      }
    );

  };

  const handleDelete = (row) => {
    if (window.confirm(`Tem certeza que deseja excluir:\r ${row.titulo}?`)) {
      removeEtl(row.id);
    }
  };

  function getEtls(id) {
    etlsService.getAll(id).then(
      (res) => {
        setEtls(res);
        if (!res || res.length == 0) {
          setNoDataString("Sem dados para mostrar.");
        }
      }
    ).catch(err => setIsError(true));
  }

  const removeEtl = (id) => {
    etlsService.delete(id)
      .then(
        (res) => {
          setEtls([]);
          getCompany();
          // return true;
        }
      )
      .catch(err => setIsError(true));
  }

  function getCompany() {
    companiesService.getById(id).then(
      (res) => {
        setCompany(res);
        getEtls(id);
      }
    ).catch(err => setIsError(true));
  }

  useEffect(() => {
    getCompany();
  }, []);

  const TabelaETLs = () => (
    <DataTable
      title="ETL's"
      columns={columns}
      data={etls}
      defaultSortField="nome"
      actions={actions}
      noDataComponent={noDataString}
      highlightOnHover={true}
      pointerOnHover={true}
      onRowClicked={(row) => { toggleHistory(row) }}
    />
  );

  const columnsHistoryETL = [
    {
      name: 'Data',
      selector: 'data',
      sortable: true,
    },
    {
      name: 'Hora',
      selector: 'hora',
      sortable: true,
    }
  ];

  const [noDataHistoryETL, setNoDataHistoryETL] = useState('Nenhuma execução registrada neste ETL.');
  const [isExecutingETL, setIsExecutingETL] = useState(false);
  const [etlHistories, setETLHistories] = useState();

  const toggleHistory = (row) => {
    if (modalHistory) {
      setModalHistory(false);
    }
    else {
      etlsService.getAllHistories(row.id)
        .then(res => {
          setETLHistories(res);
          setEtl(row);
          setModalHistory(true);
        })
        .catch(err => {
          setIsError(true);
          console.error('erro', err);
        });
    }
  };

  const updateETLHistories = () => {
    if (etl) {
      etlsService.getAllHistories(etl.id)
        .then(res => {
          setETLHistories(res);
        })
        .catch(err => {
          setIsError(true);
          console.error('erro', err);
        });
    }
  }

  const executarETL = async () => {
    setIsExecutingETL(true);
    await etlsService.executeNow(etl)
      .then((res) => {
        setIsExecutingETL(false);
        if ((res.data) && (res.data.status === 200)) {
          toast.info('ETL executado com sucesso!', { containerId: 'B' });
          updateETLHistories();
        }
        else {
          toast.warn('Erro na execução do ETL, contacte o administrador!', { containerId: 'B' });
          console.error(res.data);
        }
      })
      .catch(err => {
        if (err.response) {
          console.error(err.response.data.mensagem);
        }
        else {
          console.error(err);
        }
        setIsError(true);
        setIsExecutingETL(false);
      });
  }

  const [logETL, setLogETL] = useState('Detalhamento do log de cada execução.');
  const showLogHistory = ( log ) => {
    setLogETL(log);
  }

  const TableHistoryETL = () => (
    <DataTable
      title="Histórico de Execuções"
      columns={columnsHistoryETL}
      className='history-table-custom'
      data={etlHistories}
      noDataComponent={noDataHistoryETL}
      highlightOnHover={true}
      pointerOnHover={true}
      dense={true}
      onRowClicked={(row) => { showLogHistory(row.log) }}
      fixedHeader={true}
      fixedHeaderScrollHeight='225px'
      noHeader={true}
      selectableRowSelected={0}
    />
  );

  return (
    <Container>
      <Row>
        <Col>
          <h1>{company.nome}</h1>
        </Col>
      </Row>
      <Row >
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  toggleTab('1');
                }}>
                Lista de Painéis
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  toggleTab('2');
                }}>
                Lista de ETL's
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Row className="tab-content-company">
        <Col>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <AdmDashboard company={company} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col>
                  <Card className="p-2 my-2">
                    <Row>
                      <Col md="12">
                        <TabelaETLs />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>

      <Modal isOpen={modalNewETL} toggle={toggleNewETL} className="user-modal">
        <ModalHeader toggle={toggleNewETL}>Novo ETL</ModalHeader>
        <ModalBody>
          <EtlForm
            defaultValues={etl}
            setDefaultValues={_ =>
              setEtl({
                id: '',
                titulo: '',
                hora: '',
                idEmpresa: id
              })
            }
            {...{ onSubmit, isLoading }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleNewETL}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalHistory}
        toggle={toggleHistory}
        className="etl-detail-modal">
        <ModalHeader toggle={toggleHistory}>ETL - {etl.titulo}</ModalHeader>
        <ModalBody>
          <Container fluid>            
            <Row>
              <Col className="col-md-6">
                <TableHistoryETL/>
              </Col>
              <Col className="col-md-6">
                <textarea
                  className="log-result"
                  cols={20}
                  value={logETL ? logETL : ''}
                  placeholder="Detalhamento do LOG de cada execução."
                ></textarea>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          {isExecutingETL == true ? 
            <div>
              <div className="d-flex align-items-center flex-column px-2">
                <RingLoader color={'#122e4d'} loading={isExecutingETL} size='30'/>
              </div>
            </div>              
            :
            <Button color='primary' onClick={executarETL} >Executar ETL</Button>
          }
          <Button color='secondary' onClick={toggleHistory}>Fechar</Button>
        </ModalFooter>
      </Modal>
      <Alert color={type} isOpen={visible}>
        {message}
      </Alert>
    </Container>
  );
}