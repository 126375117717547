import React, { lazy, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { RingLoader } from 'react-spinners';

// Páginas do site principal

import PaginaLoginMonitor from '../modulos/monitor/login/PaginaLogin';
import PaginaPassRecovery from '../modulos/monitor/login/PaginaPassRecovery';
import PaginaPassDefine from '../modulos/monitor/login/PaginaPassDefine';

// Componentes de layout
import LayoutMinimo from '../modulos/comum/LayoutMinimo';
import MenuLateral from '../modulos/comum/menus/MenuLateral';

// Componente de Rota
import Route from './Route';
import DetalheEmpresa from 'modulos/monitor/empresas/detalhe/index';

// Páginas de exemplos
const Dashboard = lazy(() => import('../modulos/monitor/dashboard/index'));
const Relatorios = lazy(() => import('../modulos/monitor/relatorios/index'));
const PerfilDoUsuario = lazy(() =>
  import('../modulos/monitor/perfilDoUsuario/index')
);
const alterarSenha = lazy(() =>
  import('../modulos/monitor/alterarSenha/index')
);
const Usuarios = lazy(() => import('../modulos/monitor/usuarios/index'));
const admDashboard = lazy(() => import('../modulos/monitor/admDashboard/index'));
const Empresas = lazy(() => import('../modulos/monitor/empresas/index'));

const Routes = () => {
  // const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <RingLoader color={'#122e4d'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Aguarde enquanto as informações são carregadas...
          </div>
        </div>
      </>
    );
  };
  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          {/* ROTA DE LOGIN */}
          <Route path={['/login']}>
            <LayoutMinimo>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/login" component={PaginaLoginMonitor} />                  
                </motion.div>
              </Switch>
            </LayoutMinimo>
          </Route>
          {/* ROTA DE RECUPERAÇÃO DE SENHA */}
          <Route path={['/reset-password']}>
            <LayoutMinimo>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/reset-password" component={PaginaPassRecovery} />                  
                </motion.div>
              </Switch>
            </LayoutMinimo>
          </Route>
          {/* ROTA DE RECUPERAÇÃO DE SENHA */}
          <Route path={['/reset-password-set/:token']}>
            <LayoutMinimo>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/reset-password-set/:token" component={PaginaPassDefine} />                  
                </motion.div>
              </Switch>
            </LayoutMinimo>
          </Route>

          {/* ROTA DO DASHBOARD */}
          <Route path={['/dashboard']} isPrivate>
            <MenuLateral>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/dashboard" component={Dashboard} isPrivate />
                </motion.div>
              </Switch>
            </MenuLateral>
          </Route>

          {/* ROTA RELATORIOS */}
          <Route path={['/relatorios']} isPrivate>
            <MenuLateral>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/relatorios" component={Relatorios} isPrivate />
                </motion.div>
              </Switch>
            </MenuLateral>
          </Route>

          {/* ROTA PERFIL DO USUARIO */}
          <Route path={['/perfilDoUsuario']} isPrivate>
            <MenuLateral>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route
                    path="/perfilDoUsuario"
                    component={PerfilDoUsuario}
                    isPrivate
                  />
                </motion.div>
              </Switch>
            </MenuLateral>
          </Route>

          {/* ROTA ALTERAÇAO DE SENHA */}
          <Route path={['/alterarsenha']} isPrivate>
            <MenuLateral>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route
                    path="/alterarsenha"
                    component={alterarSenha}
                    isPrivate
                  />
                </motion.div>
              </Switch>
            </MenuLateral>
          </Route>

          {/* ROTA USERS */}
          <Route path={['/usuarios']} isPrivate>
            <MenuLateral>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/usuarios" component={Usuarios} isPrivate />
                </motion.div>
              </Switch>
            </MenuLateral>
          </Route>

          {/* ROTA ADM DASHBOARD */}
          <Route path={['/adm-dashboard']} isPrivate>
            <MenuLateral>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/adm-dashboard" component={admDashboard} isPrivate />
                </motion.div>
              </Switch>
            </MenuLateral>
          </Route>

          {/* ROTA ADM EMPRESAS */}
          <Route path={['/empresas']} isPrivate>
            <MenuLateral>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/empresas" component={Empresas} isPrivate />
                </motion.div>
              </Switch>
            </MenuLateral>
          </Route>

          <Route path={['/detalhe-empresa/:id']} isPrivate>
            <MenuLateral>
              <Switch>
                <motion.div                  
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/detalhe-empresa/:id" component={DetalheEmpresa} isPrivate />
                </motion.div>
              </Switch>
            </MenuLateral>
          </Route>          

          <Redirect exact from="/" to="/login" />
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
