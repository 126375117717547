import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebook,
  faGoogle,
  faAngellist
} from '@fortawesome/free-brands-svg-icons';
import {
  faDotCircle,
  faLightbulb
} from '@fortawesome/free-regular-svg-icons';
import {
  faTimes,
  faCheck,
  faSitemap,
  faFileInvoiceDollar,
  faMoneyCheckAlt,
  faFileInvoice,
  faCashRegister,
  faHandHoldingUsd,
  faStar,
  faSearch,
  faAngleDown,
  faSignOutAlt,
  faArrowsAltH,
  faShieldAlt
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faFacebook,
  faGoogle,
  faAngellist,
  faDotCircle,
  faLightbulb,
  faTimes,
  faCheck,
  faSitemap,
  faFileInvoiceDollar,
  faMoneyCheckAlt,
  faFileInvoice,
  faCashRegister,
  faHandHoldingUsd,
  faStar,
  faSearch,
  faAngleDown,
  faSignOutAlt,
  faArrowsAltH,
  faShieldAlt
);
