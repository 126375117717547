/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import {
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Label
} from 'reactstrap';
import { invalidMessage } from '../../comum/FormMessage';
import { CompaniesService } from '../../../services/companies.service'

export default function DashForm({
  defaultValues,
  setDefaultValues,
  onSubmit,
  isLoading
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [companie, setCompanie] = useState({value: `${defaultValues?.idEmpresa ? defaultValues.idEmpresa : ''}`, label: ""});
  const [submit, setSubmit] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const { control, handleSubmit, formState: {errors}, reset } = useForm({
    defaultValues
  });

  const companiesService = new CompaniesService();

  const [companies, setCompanies] = useState([]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="my-1">
      <Row>
        <div className="d-none">
          <Controller
            control={control}
            placeholder="ID"
            type="text"
            name="id"
            defaultValue=""
            render={({ field }) => <Input {...field} />}
          />                    
        </div>
        <Col md={defaultValues.id ? 12 : 12}>
          <FormGroup className="mb-12">
            <Label>Nome</Label>
            <Controller
              control={control}
              type="text"
              name="nome"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => <Input {...field} type='text' placeholder="Nome" />}
            />
            {errors.nome?.type === 'required' && invalidMessage('Este campo é obrigatório')}
          </FormGroup>
        </Col>
        <Col md={defaultValues.id ? 12 : 12}>
          <FormGroup className="mb-12">
            <Label>Tipo</Label>
            <Controller
              control={control}
              placeholder="Tipo"
              type="text"
              name="tipo"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => <Input {...field} type='text' placeholder="Tipo" />}
            />
            {errors.tipo?.type === 'required' && invalidMessage('Este campo é obrigatório')}
          </FormGroup>
        </Col>
        <Col md={defaultValues.id ? 12 : 12}>        
          <FormGroup className="mb-12">
            <Label>DashboardID</Label>
            <Controller
              control={control}
              placeholder="DashboardID"
              type="text"
              name="dashboardID"
              defaultValue=""             
              render={({ field }) => <Input {...field} type='text' placeholder="DashboardID" />} 
            />
            {errors.dashboardID?.type === 'required' && invalidMessage('Este campo é obrigatório')}
          </FormGroup>
        </Col>        
        <Col md={defaultValues.id ? 12 : 12}>          
          <FormGroup className="mb-12">
          <Label>ReportID</Label>
            <Controller
              control={control}
              placeholder="ReportID"
              type="text"
              name="reportID"
              defaultValue=""             
              render={({ field }) => <Input {...field} type='text' placeholder="ReportID" />} 
            />
            {errors.reportID?.type === 'required' && invalidMessage('Este campo é obrigatório')}
          </FormGroup>
        </Col>   
        <Col md={defaultValues.id ? 12 : 12}>
          <FormGroup className="mb-12">
            <Label>DatasetID</Label>
            <Controller
              control={control}
              placeholder="DatasetID"
              type="text"
              name="datasetID"
              defaultValue=""             
              render={({ field }) => <Input {...field} type='text' placeholder="DatasetID" />} 
            />
            {errors.datasetID?.type === 'required' && invalidMessage('Este campo é obrigatório')}
          </FormGroup>
        </Col>        
        <Col md={defaultValues.id ? 12 : 12}>
          <FormGroup className="mb-12">
            <Label>Ordem</Label>
            <Controller
              control={control}
              placeholder="Ordem"
              type="text"
              name="ordem"
              defaultValue=""             
              render={({ field }) => <Input {...field} type='text' placeholder="Ordem" />} 
            />
            {errors.ordem?.type === 'required' && invalidMessage('Este campo é obrigatório')}
          </FormGroup>
        </Col>        
        <Col
          md={{
            size: !defaultValues.id ? 7 : 3,
            offset: !defaultValues.id ? 5 : 0
          }}>
          <Button
            color="primary"
            type="submit"
            onClick={_ => {
              setSubmit(true);              
            }}
            disabled={isLoading ? true : false}
            className="float-right font-weight-bold">
            {defaultValues.id ? 'Salvar' : 'Criar Dashboard'}
          </Button>
        </Col>
      </Row>
    </form>
  );
}
