import axios from 'axios';

import { SERVER_HOST, SERVER_PORT } from '../config/constantes';
const token = sessionStorage.getItem('@ProsperMonitor:bpoToken');
const AuthStr = 'Bearer '.concat(token);

const api = axios.create({
  baseURL: `${SERVER_HOST}:${SERVER_PORT}`,
  headers: { Authorization: AuthStr }
});

export default api;
