import api from "./api";

export class EtlsService {  

  async getAll(id) {
      return await api
            .get('etl/from/'+id)
            .then(res => res.data);  
  }

  async getAllHistories(id) {
    return await api.get('etl/historico/'+id)            
      .then(res => res.data);  
  }

  async getById(id) {
      return await api
            .get('etl/'+id)
            .then(res => res.data[0]);  
  }

  async execById(id) {
      return await api
            .get('etl/exec/'+id)
            .then(res => res.data[0]);  
  }

  async executeNow(etl) {
    return await api.post('etl/execute-now', {'etl': etl})
      .then(res => res);  
  }

  async create(postData) {
    return await api
          .post(`etl`, postData)
          .then(res => res.data);
  }

  async delete(id) {
    return await api
          .delete(`etl/${id}`)
          .then(res => res.data);
  }

} 