/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  Button
} from 'reactstrap';
import { invalidMessage } from '../../../comum/FormMessage';

export default function EtlForm({
  defaultValues,
  setDefaultValues,
  onSubmit,
  isLoading
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [value, setValue] = useState('Empresa');
  const [submit, setSubmit] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const { control, handleSubmit, formState: {errors}, reset } = useForm({
    defaultValues
  });

  const changeValue = e => {
    setValue(
      e.currentTarget.value
    );
  };
  
  useEffect(() => {    
    reset(defaultValues);
  }, [defaultValues]);

  const newEtl = () => {
    setSubmit(false);
    setDefaultValues();  
    setValue('Empresa');  
    reset({
      id: '',
      titulo: '',      
      hora: '',
      idEmpresa: ''
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="my-1">
      <Row>
        <div className="d-none">
          <Controller
            control={control}
            placeholder="ID"
            type="text"
            name="id"
            defaultValue=""
            render={({ field }) => <Input {...field} />}
          />
        </div>
        <Col md={defaultValues.id ? 12 : 12}>
          <FormGroup className="mb-12">
            <Label>Título</Label>
            <Controller
              control={control}
              placeholder="Título"
              type='text'
              name="titulo"
              rules={{ required: true }}
              autoFocus={true}
              render={({ field }) => <Input {...field} type='text' />}
            />
            {errors.titulo?.type === 'required' && invalidMessage('Este campo é obrigatório')}
          </FormGroup>
        </Col>
        <Col md={defaultValues.id ? 12 : 12}>
          <FormGroup className="mb-12">
            <Label>Hora</Label>
            <Controller
              control={control}
              placeholder="Hora Ex: 22:00"
              type="text"
              name="hora"
              rules={{ required: true }}
              render={({ field }) => <Input {...field} type='text' />}
            />
            {errors.hora?.type === 'required' && invalidMessage('Este campo é obrigatório')}
          </FormGroup>
        </Col>        
        {defaultValues.id && (
          <Col md={{ size: 3, offset: 8 }}>
            <Button
              color="primary"
              type="button"
              disabled={isLoading ? true : false}
              className="float-right font-weight-bold"
              onClick={newEtl}>
              Novo ETL
            </Button>
          </Col>
        )}
        <Col
          md={{
            size: !defaultValues.id ? 7 : 3,
            offset: !defaultValues.id ? 5 : 0
          }}>
          <Button
            color="primary"
            type="submit"
            onClick={_ => {
              setSubmit(true);              
            }}
            disabled={isLoading ? true : false}
            className="float-right font-weight-bold">
            {defaultValues.id ? 'Salvar' : 'Criar ETL'}
          </Button>
        </Col>
      </Row>
    </form>
  );
}
