/* eslint-disable */
import React from 'react';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

import './Menu.css';
import Menu from './Menu.js';
import MenuSuperiorCabecalho from './MenuSuperiorCabecalho.js';

const MenuLateral = props => {
  const {
    children,
    sidebarToggle,
    sidebarToggleMobile,
    sidebarFixed,
    headerFixed,
    headerSearchHover,
    headerDrawerToggle,
    contentBackground
  } = props;

  const [collapsed, setCollapsed] = useState(false);
  const [menuStateClass, setMenuStateClass] = useState('');

  const toggleMenu = async () => {
    if(collapsed) {
      setCollapsed(false);
      setMenuStateClass('');
    }else {
      setCollapsed(true);
      setMenuStateClass('menuCollapsed');
    }        
  }

  const verifyClose = () => {
    if(window.outerWidth < 900){
      setCollapsed(true); 
      setMenuStateClass('menuCollapsed');
    }
  }

  useEffect(() => {
    if(window.outerWidth < 900){
      setCollapsed(true); 
      setMenuStateClass('menuCollapsed');
    }
  }, []);

  return (
    <>
      <div
        className={clsx('app-wrapper', contentBackground, {
          'header-drawer-open': headerDrawerToggle,
          'app-sidebar-collapsed': sidebarToggle,
          'app-sidebar-mobile-open': sidebarToggleMobile,
          'app-sidebar-fixed': sidebarFixed,
          'app-header-fixed': headerFixed,
          'search-wrapper-open': headerSearchHover
        })}>
        <div className={menuStateClass}>
          <p className="flagMenu" onClick={toggleMenu}>
            {collapsed == false ? <FaArrowLeft /> : <FaArrowRight />}
          </p>
          <Menu collapsed={collapsed} verifyClose={verifyClose}/>
        </div>
        <div className="app-main">
          <div className="app-content">{children}</div>
        </div>
      </div>
    </>
  );
};

MenuLateral.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  contentBackground: state.ThemeOptions.contentBackground
});

export default connect(mapStateToProps)(MenuLateral);
