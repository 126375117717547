/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import './Login.css'
import { useAuth } from './Autenticacao';
import { useParams } from "react-router";


import {
  FormGroup,
  Input,
  Button
} from 'reactstrap';

import logoProsper from '../../../assets/images/logo-prosper.png';

import { invalidMessage } from '../../comum/FormMessage';


export default function PaginaPassDefine() {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [valid, setValid] = useState(true);
  const [senha, setSenha] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  let { token } = useParams();

  const { control, handleSubmit, formState: {errors} } = useForm();

  const { definePassword } = useAuth();

  const history = useHistory();

  const onSubmit = useCallback(
    async data => {            
      try {
        if(data.senha_novamente != data.senha){          
          errors.senha_novamente = 'erro';
          setIsError(true);          
        }
        setIsLoading(true);
        const result = await definePassword({
          token,
          senha: data.senha       
        });

        if (result.status === 200) {
          history.push('/login');
        }
      } catch (err) {
        console.log(err);
        setIsError(true);
      }
      setIsLoading(false);
    },
    [history, definePassword]
  );

  const setPassValue = (pass) => {
    setSenha(pass[0].target.value);
    console.log(pass[0].target.value);
    console.log(senha);
  }

  const validateConfirmation = (pass) => {
    setPasswordConfirmation(pass[0].target.value);    
    if(pass[0].target.value != senha) {
      setValid(false);
    }else {
      setValid(true);
      setIsLoading(false);
    }    
  }

  useEffect(() => {
    
  }, [isError]);

  return (
    <div className="telaprincipal">
      <div className="telalogin">
        <div className="logopequeno">
        <img
              className="mt-4 mb-4 imgCustom"                            
              src={logoProsper}
              alt="Prosper"
            />
        </div>
        <div className="caixalogin">
        <form
                onSubmit={handleSubmit(onSubmit)}
                className="">
                  <p className="label-login">Nova senha:</p>
                <FormGroup className="input-custom">                
                  <Controller
                    control={control}
                    placeholder="Nova senha"
                    type="password"
                    name="senha"                          
                    defaultValue={senha}                           
                    rules={{ required: true }}   
                    render={({ field }) => <Input {...field} type='password'/>}        
                  />
                  {errors.senha?.type === 'required' &&
                    invalidMessage('Este campo é obrigatório')}
                </FormGroup> 
                <p className="label-login">Repetir senha:</p>
                <FormGroup className="input-custom">
                  <Controller
                    control={control}
                    placeholder="Repetir senha"
                    type="password"
                    name="senha_novamente"
                    defaultValue=""                    
                    value={passwordConfirmation} 
                    rules={{ required: true }}      
                    render={({ field }) => <Input {...field} type='password' />}          
                  /> 
                  {errors.senha_novamente?.type == 'required' &&
                    invalidMessage('Este campo é obrigatório')}                 
                  {errors.senha_novamente ?
                    <span className="error-confirmation">Confirmação de senha não confere.</span> : ''}
                </FormGroup> 
               
                <div className="text-center py-2">
                  <Button className="buttonentrar"
                    color="primary"
                    type="submit"
                    disabled={isLoading ? true : false}
                    >
                    Definir
                   </Button>                   
                    
                </div>
                <div>
               
                </div>
              </form>
        </div>
        <p className="text-rodape"> Prosper - Gestão Inteligente 2021</p> 
      </div>

      <div className="mainscreen"></div>
    </div>
  );
}
